
import { Component, Prop } from 'vue-property-decorator';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import { cloneDeep } from 'lodash';
import { IpdcProductTheme } from '@govflanders/mbp-admin-panel-shared';
import ThemeForm from '@/components/ipdc/ThemeForm.vue';
import RoleGuard from '@/utility/auth/RoleGuard.vue';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    ThemeForm,
    Anchor,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
  },
})
export default class IpdcProductThemeEdit extends mixins(RoleGuard) {
  @Prop({
    type: String,
    required: true,
  })
  public id!: string;

  public value: IpdcProductTheme = null;
  public initialValue: IpdcProductTheme = null;
  public valueLoading = false;
  public error = '';

  private created() {
    this.valueLoading = true;
    this.$store
      .dispatch('ipdcProductTheme/get', this.id)
      .then(ipdcProductTheme => {
        this.value = cloneDeep(ipdcProductTheme);
        this.initialValue = cloneDeep(ipdcProductTheme);
        this.valueLoading = false;
      })
      .catch(e => {
        this.error = e;
        this.valueLoading = false;
      });
  }

  public revert() {
    this.value = cloneDeep(this.initialValue);
  }

  public save() {
    this.$store
      .dispatch('ipdcProductTheme/update', this.value)
      .then(updatedValue => {
        this.value = updatedValue;
        this.initialValue = cloneDeep(updatedValue);
        this.$toast.success(this.$t('ipdc.mbp-theme.edit.save-success'));
      })
      .catch(e => {
        console.error(e);
        this.$toast.error(this.$t('ipdc.mbp-theme.edit.save-error'));
      });
  }
}
