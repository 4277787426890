
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IpdcProductTheme } from '@govflanders/mbp-admin-panel-shared';
import UuidField from '@/components/shared/forms/UuidField.vue';

@Component({
  components: {
    UuidField,
  },
})
export default class ThemeForm extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: IpdcProductTheme;
}
